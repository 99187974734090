<template>
    <a-drawer
        :zIndex="1000"
        :width="500"
        @close="onClose"
        class="adjust-drawer-width"
        :visible="visible"
        :wrapStyle="{ overflow: 'auto' }"
    >
        <div class="dF jSB aC mb-3 mr-3">
            <h4>Filters</h4>
			<div v-if="filterApplied" style="display:inline-block">
				<div @click="clearFilter" class="dF aC py-2 px-4" style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer"><div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" /></div>
			</div>
        </div>
        <!-- lowrise filter all inventory -->
        <div
            style="padding-bottom: 52px"
            v-if="
                instance.productType == 'lowrise' &&
                (['inventory', 'allocation'].includes($store.state.inventory.crumbs)) &&
                visible
            "
        >
			<div v-if="isReservationOrWorksheetActive">
				<div v-if="$userType !== 'agent'">
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option
								v-for="realtor in realtors"
								:key="realtor.id"
								:value="realtor.id"
								>{{realtor.firstName}} {{realtor.lastName}} <span>{{'('+realtor.email+')'}}</span></a-select-option
							>
						</a-select>
					</div>
				</div>
				<div v-else>
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option value="none">None</a-select-option>
							<a-select-option :value="user.id">{{user.firstName}} {{user.lastName}} <span>{{'('+user.email+')'}}</span></a-select-option>
						</a-select>
					</div>
				</div>
			</div>

            <div v-if="filter.price?.length" class="mt-5">
                <h6>Lot Premium:</h6>
                <a-slider
                    @change="changePriceRange"
                    v-if="visible"
                    :default-value="filter.price"
                    range
                    :min="0"
                    :max="lotsByPremiums.max"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Lot Frontage:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeSize(0)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(0)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="size in frontages"
                        :key="size"
                    >
                        <div
                            @click="changeSize(size)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(size)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ size }}
                        </div>
                    </a-col>
                </a-row>
            </div>

			<div class="mt-5">
                <div class="mt-5">
					<h6>Home Model:</h6>
					<a-row :gutter="[16, 16]">
						<a-col :span="24">
							<a-select
								style="width: 100%"
								v-model="filter.unitGroup"
							>
								<a-select-option value="any">Any</a-select-option>
								<a-select-option
									v-for="uG in uGs"
									:key="uG.id"
									:value="uG.id"
									>{{ uG.name }}</a-select-option
								>
							</a-select>
						</a-col>
					</a-row>
				</div>
				<div class="mt-5">
					<h6>Elevation:</h6>
					<a-row :gutter="[16, 16]">
						<a-col :span="24">
							<a-select
								style="width: 100%"
								v-model="filter.elevation"
							>
								<a-select-option value="any">Any</a-select-option>
								<a-select-option
									v-for="(name, nameI) in elevationName"
									:key="name + nameI"
									:value="name"
									>{{ name }}</a-select-option
								>
							</a-select>
						</a-col>
					</a-row>
				</div>
            </div>

            <div class="mt-5">
                <h6>Model Type:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeType('any')"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="(uG, uGI) in unitGroups"
                        :key="uG + uGI"
                    >
                        <div
                            @click="changeType(uG)"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes(uG)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ uG }}
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Lot Condition / Premium:</h6>
                <a-row :gutter="16">
                    <div class="grid-display-3" style="padding: 0 8px">
                        <div
                            @click="changePremium('any')"
                            :style="
                                filter.premiums &&
                                filter.premiums.length &&
                                filter.premiums.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options dF aC jC"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            <div>Any</div>
                        </div>
						<div
                            @click="changePremium('none')"
                            :style="
                                filter.premiums &&
                                filter.premiums.length &&
                                filter.premiums.includes('none')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options dF aC jC"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            <div>No Premium</div>
                        </div>
                        <div
                            v-for="(premium, premiumI) in premiums"
                            :key="premium + premiumI"
                            @click="changePremium(premium.value)"
                            :style="
                                filter.premiums &&
                                filter.premiums.length &&
                                filter.premiums.includes(premium.value)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options dF aC jC"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            <div>{{ premium.label }}</div>
                        </div>
                    </div>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Street Name:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="24">
                        <a-select style="width: 100%" v-model="filter.street">
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="street in streets"
                                :key="street"
                                :value="street"
                                >{{ street }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>

            <div v-if="tags && tags.length" class="mt-5">
                <h6>Lot Tags:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeTag('any')"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="tag in tags" :key="tag.id">
                        <div
                            @click="changeTag(tag.id)"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes(tag.id)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ tag.name }}
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <!-- lowrise filter lot -->

        <div
            style="padding-bottom: 52px"
            v-else-if="
                instance.productType == 'lowrise' &&
                $store.state.inventory.crumbs == 'priceManagement' &&
                priceManagement == 'lot' &&
                visible
            "
        >
            <div v-if="filter.price && filter.price.length">
                <h6>Lot Premium Price:</h6>
                <a-slider
                    @change="changePriceRange"
                    v-if="visible"
                    :default-value="filter.price"
                    range
                    :min="0"
                    :max="lotsByPremiums.max"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Lot Frontage:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeSize(0)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(0)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="size in frontages"
                        :key="size"
                    >
                        <div
                            @click="changeSize(size)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(size)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ size }}
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Model Type:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeType('any')"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="(uG, uGI) in unitGroups"
                        :key="uG + uGI"
                    >
                        <div
                            @click="changeType(uG)"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes(uG)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ uG }}
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Status:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeStatus('any')"
                            :style="
                                filter.status &&
                                filter.status.length &&
                                filter.status.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="stat in statuses"
                        :key="stat.value"
                    >
                        <div
                            @click="changeStatus(stat.value)"
                            :style="
                                filter.status &&
                                filter.status.length &&
                                filter.status.includes(stat.value)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ stat.label }}
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Lot Condition / Premium:</h6>
                <a-row :gutter="16">
                    <div class="grid-display-3" style="padding: 0 8px">
                        <div
                            @click="changePremium('any')"
                            :style="
                                filter.premiums &&
                                filter.premiums.length &&
                                filter.premiums.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options dF aC jC"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            <div>Any</div>
                        </div>
                        <div
                            v-for="(premium, premiumI) in premiums"
                            :key="premium + premiumI"
                            @click="changePremium(premium.value)"
                            :style="
                                filter.premiums &&
                                filter.premiums.length &&
                                filter.premiums.includes(premium.value)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options dF aC jC"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            <div>{{ premium.label }}</div>
                        </div>
                    </div>
                </a-row>
            </div>

            <div class="mt-5">
                <h6>Street Name:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="24">
                        <a-select style="width: 100%" v-model="filter.street">
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="street in streets"
                                :key="street"
                                :value="street"
                                >{{ street }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>

            <div v-if="tags && tags.length" class="mt-5">
                <h6>Lot Tags:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeTag('any')"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="tag in tags" :key="tag.id">
                        <div
                            @click="changeTag(tag.id)"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes(tag.id)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ tag.name }}
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <!-- lowrise filter elevation -->
        <div
            style="padding-bottom: 52px"
            v-else-if="
                instance.productType == 'lowrise' &&
                $store.state.inventory.crumbs == 'priceManagement' &&
                priceManagement == 'elevation' &&
                visible
            "
        >
            <div v-if="filter.price && filter.price.length">
                <h6>Elevation Price:</h6>
                <a-slider
                    @change="changePriceRange"
                    v-if="visible"
                    :default-value="filter.price"
                    range
                    :min="0"
                    :max="elevationPrices.max"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
            <div :class="filter.price && filter.price.length > 0 ? 'mt-5' : ''">
                <h6>Model Type:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="8">
                        <div
                            @click="changeType('any')"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :span="8"
                        v-for="(uG, uGI) in unitGroups"
                        :key="uG + uGI"
                    >
                        <div
                            @click="changeType(uG)"
                            :style="
                                filter.modelType &&
                                filter.modelType.length &&
                                filter.modelType.includes(uG)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ uG }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Frontage:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeSize(0)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(0)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="size in frontages"
                        :key="size"
                    >
                        <div
                            @click="changeSize(size)"
                            :style="
                                filter.frontage &&
                                filter.frontage.length &&
                                filter.frontage.includes(size)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ size }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Beds:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="8" :sm="6">
                        <div
                            @click="changeBed('any')"
                            :style="
                                filter.beds &&
                                filter.beds.length &&
                                filter.beds.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="8"
                        :sm="6"
                        v-for="bed in bedOptions"
                        :key="bed"
                        :value="bed"
                    >
                        <div
                            @click="changeBed(bed)"
                            :style="
                                filter.beds &&
                                filter.beds.length &&
                                filter.beds.includes(bed)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ bed }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Baths:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="8" :sm="6">
                        <div
                            @click="changeBath('any')"
                            :style="
                                filter.baths &&
                                filter.baths.length &&
                                filter.baths.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="8"
                        :sm="6"
                        v-for="bath in bathOptions"
                        :key="bath"
                        :value="bath"
                    >
                        <div
                            @click="changeBath(bath)"
                            :style="
                                filter.baths &&
                                filter.baths.length &&
                                filter.baths.includes(bath)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ bath }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Home Model:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="24">
                        <a-select
                            style="width: 100%"
                            v-model="filter.unitGroup"
                        >
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="uG in uGs"
                                :key="uG.id"
                                :value="uG.id"
                                >{{ uG.name }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Elevation:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="24">
                        <a-select
                            style="width: 100%"
                            v-model="filter.elevation"
                        >
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="(name, nameI) in elevationName"
                                :key="name + nameI"
                                :value="name"
                                >{{ name }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>

            <div v-if="tags && tags.length" class="mt-5">
                <h6>Tags:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeTag('any')"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="tag in tags" :key="tag.id">
                        <div
                            @click="changeTag(tag.id)"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes(tag.id)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ tag.name }}
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <!-- highrise filter -->
        <div
            style="padding-bottom: 52px"
            v-else-if="instance.productType == 'highrise' && visible && !showAddOnsFilter"
        >
			<div v-if="isReservationOrWorksheetActive">
				<div v-if="$userType !== 'agent'">
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option
								v-for="realtor in realtors"
								:key="realtor.id"
								:value="realtor.id"
								>{{realtor.firstName}} {{realtor.lastName}} <span>{{'('+realtor.email+')'}}</span></a-select-option
							>
						</a-select>
					</div>
				</div>
				<div v-else>
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option value="none">None</a-select-option>
							<a-select-option :value="user.id">{{user.firstName}} {{user.lastName}} <span>{{'('+user.email+')'}}</span></a-select-option>
						</a-select>
					</div>
				</div>
			</div>

            <div class="mt-5">
                <h6>Type:</h6>
                <a-row :gutter="16">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeHighRiseType('any')"
                            :style="
                                filter.type &&
                                filter.type.length &&
                                filter.type.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeHighRiseType('condoUnit')"
                            :style="
                                filter.type &&
                                filter.type.length &&
                                filter.type.includes('condoUnit')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Condo Unit
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeHighRiseType('condoTown')"
                            :style="
                                filter.type &&
                                filter.type.length &&
                                filter.type.includes('condoTown')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Condo Town
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Price:</h6>
                <a-slider
                    v-if="visible"
                    @change="changePriceRange"
                    range
                    :default-value="filter.price"
                    :min="minPrice"
                    :max="maxPrice"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.price[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
            <div
                :class="filter.price && filter.price.length ? 'mt-5' : ''"
                v-if="filter.sqft && filter.sqft.length"
            >
                <h6>Square Foot:</h6>
                <a-slider
                    v-if="visible"
                    @change="changeSqftRange"
                    range
                    :default-value="filter.sqft"
                    :min="minSqft"
                    :max="maxSqft"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            {{ filter.sqft[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            {{ filter.sqft[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
            <div :class="filter.sqft && filter.sqft.length > 0 ? 'mt-5' : ''">
                <h6>Beds:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeBed('any')"
                            :style="
                                filter.beds &&
                                filter.beds.length &&
                                filter.beds.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :xs="12"
                        :sm="8"
                        v-for="(bed, bedI) in bedOptions"
                        :key="bedI + bed"
                        :value="bed"
                    >
                        <div
                            @click="changeBed(bed)"
                            :style="
                                filter.beds &&
                                filter.beds.length &&
                                filter.beds.includes(bed)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ bed }} Bedroom{{
                                bed === "1" || bed === "1+1" ? "" : "s"
                            }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Baths:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="12">
                        <a-select style="width: 100%" v-model="filter.baths">
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="bath in bathOptions"
                                :key="bath"
                                :value="bath"
                                >{{ bath }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Floor Level:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="12">
                        <a-select style="width: 100%" v-model="filter.floor">
                            <a-select-option value="any">Any</a-select-option>
                            <a-select-option
                                v-for="floor in unitGroups"
                                :key="floor.value"
                                :value="floor.value"
                                >{{ floor.label }}</a-select-option
                            >
                        </a-select>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Exposure:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="3">
                        <div
                            @click="changeExposure('any')"
                            :style="
                                filter.exposure &&
                                filter.exposure.length &&
                                filter.exposure.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :span="3"
                        v-for="exp in exposure"
                        :key="exp.value"
                        :value="exp.value"
                    >
                        <div
                            @click="changeExposure(exp.value)"
                            :style="
                                filter.exposure &&
                                filter.exposure.length &&
                                filter.exposure.includes(exp.value)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ exp.label }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div v-if="highRiseExteriors.length" class="mt-5">
                <h6>Exteriors</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :span="6">
                        <div
                            @click="changeExterior('any')"
                            :style="
                                filter.exterior &&
                                filter.exterior.length &&
                                filter.exterior.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col
                        :span="6"
                        v-for="ext in highRiseExteriors"
                        :key="ext"
                    >
                        <div
                            @click="changeExterior(ext)"
                            :style="
                                filter.exterior &&
                                filter.exterior.length &&
                                filter.exterior.includes(ext)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ exteriors[ext] }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div v-if="tags && tags.length" class="mt-5">
                <h6>Unit Tags:</h6>
                <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeTag('any')"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="tag in tags" :key="tag.id">
                        <div
                            @click="changeTag(tag.id)"
                            :style="
                                filter.tags &&
                                filter.tags.length &&
                                filter.tags.includes(tag.id)
                                    ? 'background-color: #CAC3D0;'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ tag.name }}
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>

		<div
            style="padding-bottom: 52px"
            v-else-if="instance.productType == 'highrise' && visible && showAddOnsFilter"
        >
			<div v-if="isReservationOrWorksheetActive">
				<div v-if="$userType !== 'agent'">
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option
								v-for="realtor in realtors"
								:key="realtor.id"
								:value="realtor.id"
								>{{realtor.firstName}} {{realtor.lastName}} <span>{{'('+realtor.email+')'}}</span></a-select-option
							>
						</a-select>
					</div>
				</div>
				<div v-else>
					<h6>Realtors / Assignees :</h6>
					<div>
						<a-select style="width: 100%" v-model="filter.assignee" mode="multiple" @change="changeAssignee">
							<a-select-option value="any">Any</a-select-option>
							<a-select-option value="none">None</a-select-option>
							<a-select-option :value="user.id">{{user.firstName}} {{user.lastName}} <span>{{'('+user.email+')'}}</span></a-select-option>
						</a-select>
					</div>
				</div>
			</div>
            <div class="mt-5">
                <h6>Parking Type:</h6>
                <a-row :gutter="16">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeAddOnType('any', 'parkingType')"
                            :style="
                                filter.parkingType &&
                                filter.parkingType.length &&
                                filter.parkingType.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="(t, index) in parkingTypes" :key="t+index">
                        <div
                            @click="changeAddOnType(t, 'parkingType')"
                            :style="
                                filter.parkingType &&
                                filter.parkingType.length &&
                                filter.parkingType.includes(t)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ t }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Parking Price:</h6>
                <a-slider
                    v-if="visible"
                    @change="(e) => changeAddOnPriceRange(e, 'parkingPrice')"
                    range
                    :default-value="filter.parkingPrice"
                    :min="minParkingPrice"
                    :max="maxParkingPrice"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.parkingPrice[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.parkingPrice[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
			<div class="mt-5">
                <h6>Locker Type:</h6>
                <a-row :gutter="16">
                    <a-col :xs="12" :sm="8">
                        <div
                            @click="changeAddOnType('any', 'lockersType')"
                            :style="
                                filter.lockersType &&
                                filter.lockersType.length &&
                                filter.lockersType.includes('any')
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            Any
                        </div>
                    </a-col>
                    <a-col :xs="12" :sm="8" v-for="(t, index) in lockersTypes" :key="t+index">
                        <div
                            @click="changeAddOnType(t, 'lockersType')"
                            :style="
                                filter.lockersType &&
                                filter.lockersType.length &&
                                filter.lockersType.includes(t)
                                    ? 'background-color: #CAC3D0'
                                    : ''
                            "
                            class="py-1 px-2 filter-options"
                            style="
                                border: #d0c9d6 1px solid;
                                border-radius: 6px;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            "
                        >
                            {{ t }}
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="mt-5">
                <h6>Locker Price:</h6>
                <a-slider
                    v-if="visible"
                    @change="(e) => changeAddOnPriceRange(e, 'lockersPrice')"
                    range
                    :default-value="filter.lockersPrice"
                    :min="minLockersPrice"
                    :max="maxLockersPrice"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.lockersPrice[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.lockersPrice[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
			<div class="mt-5">
                <h6>Bike Rack Price:</h6>
                <a-slider
                    v-if="visible"
                    @change="(e) => changeAddOnPriceRange(e, 'bikeRacksPrice')"
                    range
                    :default-value="filter.bikeRacksPrice"
                    :min="minBikeRacksPrice"
                    :max="maxBikeRacksPrice"
                />
                <a-row :gutter="16">
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.bikeRacksPrice[0].toLocaleString("US") }}
                        </div></a-col
                    >
                    <a-col :span="4"
                        ><div style="text-align: center">-</div></a-col
                    >
                    <a-col :span="10"
                        ><div style="border-bottom: 1px solid #000">
                            ${{ filter.bikeRacksPrice[1].toLocaleString("US") }}
                        </div></a-col
                    >
                </a-row>
            </div>
		</div>
        <div
            :style="{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }"
        >
            <div class="dF jE">
                <div class="dF">
                    <a-button
                        :style="{ marginRight: '8px' }"
                        @click="onClose"
                        style="
                            border: none !important;
                            background-color: #ece9f1;
                            width: 150px;
                        "
                        >CANCEL</a-button
                    >
                    <a-button
                        @click="submit"
                        type="primary"
                        style="width: 150px"
                        >APPLY</a-button
                    >
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
export default {
	props: {
		selectedTab: {
			default: 'units',
			type: String,
		}
	},
    watch: {
        visible: {
            handler(val) {
                if (val) {
                    if (
                        (['inventory', 'allocation'].includes(this.crumbs)) &&
                        this.instance.productType == "lowrise"
                    ) {
                        this.getFilter("lowrise");
                    } else if (
                        this.crumbs == "priceManagement" &&
                        this.instance.productType == "lowrise" &&
                        this.priceManagement == "lot"
                    ) {
                        this.getFilter("lot");
                    } else if (
                        this.crumbs == "priceManagement" &&
                        this.instance.productType == "lowrise" &&
                        this.priceManagement == "elevation"
                    ) {
                        this.getFilter("elevation");
                    } else if (this.instance.productType == "highrise") {
                        this.getFilter("highrise");
                    }
                }
            },
        },
    },
    computed: {
        elevationName() {
            return this.$store.getters.elevationName;
        },
        elevationPrices() {
            return this.$store.getters.elevationPrices;
        },
        priceManagement() {
            return this.$store.state.inventory.priceManagement;
        },
        storePremiums() {
            return this.$store.state.inventory.premiums;
        },
        premiums() {
            let premiums = [];
            Object.keys(this.$store.state.inventory.premiums).forEach((key) => {
                let obj = {};
                obj.label = this.storePremiums[key].name;
                obj.value = key;
                premiums.push(obj);
            });
            return premiums;
        },
        lotsByPremiums() {
            return this.$store.getters.lotsByPremiums;
        },
        tags() {
            let tags = this.$store.state.inventory.tags;
            let type = "";

            if (
                this.instance.productType == "lowrise" &&
                this.$store.state.inventory.crumbs == "inventory"
            )
                type = "lots";
            if (
                this.instance.productType == "lowrise" &&
                this.$store.state.inventory.crumbs == "priceManagement" &&
                this.priceManagement == "lot"
            )
                type = "lots";
            if (
                this.instance.productType == "lowrise" &&
                this.$store.state.inventory.crumbs == "priceManagement" &&
                this.priceManagement == "elevation"
            )
                type = "models";
            if (this.instance.productType == "highrise") type = "models";

            return Object.values(tags).filter((x) => {
                return x && x.id && x.type == type;
            });
        },
        crumbs() {
            return this.$store.state.inventory.crumbs;
        },
        visible() {
            return this.$store.state.inventory.filterDrawer.visible;
        },
        instance() {
            return this.$store.state.instance;
        },
        lots() {
            return this.$store.state.inventory.lots;
        },
        units() {
            let units = {};
            if (this.$store.state.instance.productType == "lowrise") {
                Object.values(this.$store.state.inventory.units).forEach(
                    (unit) => {
                        if (unit.buildingType == "models") {
                            units[unit.id] = unit;
                        }
                    }
                );
            } else if (this.$store.state.instance.productType == "highrise") {
                Object.values(this.$store.state.inventory.units).forEach(
                    (unit) => {
                        if (unit.buildingType == "condounits") {
                            units[unit.id] = unit;
                        }
                    }
                );
            }
            return units;
        },
        frontages() {
            let frontages = [];
            if (this.$store.state.instance.productType == "lowrise") {
                Object.values(this.$store.state.inventory.unitGroups).forEach(
                    (uG) => {
                        if (
                            uG.buildingType == "models" &&
							uG.size && !frontages.includes(uG.size)
                        ) {
                            frontages.push(uG.size);
                        }
                    }
                );
            }

            return frontages;
        },
        uGs() {
            let unitGroups = [];
            if (this.$store.state.instance.productType == "lowrise") {
                Object.values(this.$store.state.inventory.unitGroups).forEach(
                    (uG) => {
                        if (uG.buildingType == "models") {
                            unitGroups.push(uG);
                        }
                    }
                );
            }
            return unitGroups;
        },
        unitGroups() {
            let unitGroups = [];
            if (this.$store.state.instance.productType == "lowrise") {
                Object.values(this.$store.state.inventory.unitGroups).forEach(
                    (uG) => {
                        if (
                            uG.buildingType == "models" &&
                            uG.type && !unitGroups.includes(uG.type)
                        ) {
                            unitGroups.push(uG.type);
                        }
                    }
                );
            } else {
                Object.values(this.$store.state.inventory.unitGroups).forEach(
                    (uG) => {
                        if (uG.buildingType == "condounits") {
                            unitGroups.push({
                                label: uG.name,
                                value: uG.id,
                            });
                        }
                    }
                );
            }
            return unitGroups;
        },
        streets() {
            let streets = [];
            Object.values(this.lots).forEach((lot) => {
                if (!streets.includes(lot.street) && lot.street != "") {
                    streets.push(lot.street);
                }
            });
            return streets;
        },
        elevations() {
            let elevations = [];
            Object.values(this.units).forEach((unit) => {
                if (unit.buildingType == "models" && unit.unitGroup) {
                    let ele = JSON.parse(JSON.stringify(unit));
                    ele.displayName = `${unit.unitGroup.name} ${unit.name}`;
                    elevations.push(ele);
                }
            });
            return elevations;
        },
        exposure() {
            let exposureList = [];
			let exposureMap = {}; // Using an object for faster lookups

			Object.values(this.units).forEach((x) => {
				let exposure = {
					label: "",
					value: "",
				};

				// Determine the exposure label
				let exposureLabel =
					x.packages[0].exposure == "north" ? "N" :
					x.packages[0].exposure == "south" ? "S" :
					x.packages[0].exposure == "east" ? "E" :
					x.packages[0].exposure == "west" ? "W" :
					x.packages[0].exposure == "northEast" ? "N/E" :
					x.packages[0].exposure == "southEast" ? "S/E" :
					x.packages[0].exposure == "southWest" ? "S/W" :
					x.packages[0].exposure == "northwest" ? "N/W" : "N/A";

				// Only add to exposureList if the exposure is not "none" and not already included
				if (x.packages[0].exposure !== "none" && !exposureMap[exposureLabel]) {
					exposure.label = exposureLabel;
					exposure.value = x.packages[0].exposure;
					exposureList.push(exposure);
					exposureMap[exposureLabel] = true; // Mark this exposure as included
				}
			});
            return exposureList;
        },
		realtors() {
			return this.$store.state.realtors
		},
		user() {
			return this.$store.state.user.user
		},
		showAddOnsFilter() {
			return this.$route.path.includes("unitAddOns") || ['parking', 'lockers', 'bikeRacks'].includes(this.selectedTab)
		},
        isReservationOrWorksheetActive() {
            return (this.$store.getters.hasReservationAccess && this.$store.getters.isReservationActive) || (this.$store.getters.hasWorksheetAppAccess && this.$store.getters.isWorksheetActive)
        }
    },
    data() {
        return {
            filterApplied: false,
            statuses: [
                { label: "Available", value: "available" },
                // { label: "Sold", value: "sold" },
                // { label: "Hold", value: "hold" },
                // { label: "Sold Conditional", value: "conditional" },
                { label: "Inventory", value: "inventory" },
                { label: "Not Released", value: "notreleased" },
            ],
            status: [
                { label: "Available", value: "available" },
                { label: "Sold", value: "sold" },
                { label: "Hold", value: "hold" },
                { label: "Sold Conditional", value: "conditional" },
                { label: "Inventory", value: "inventory" },
                { label: "Not Released", value: "notreleased" },
            ],
            bedLookup: {},
            filter: {},
            bedOptions: [],
            bathOptions: [],
            price: [],
            minPrice: 0,
            maxPrice: 0,
            minSqft: 0,
            maxSqft: 0,
            sqft: [],
            exteriors: {
                den: "Den",
                terrace: "Terrace",
                penthouse: "Loft/Second Level",
            },
            highRiseExteriors: [],
			minParkingPrice: 0,
			maxParkingPrice: 0,
			minLockersPrice: 0,
			maxLockersPrice: 0,
            minBikeRacksPrice: 0,
			maxBikeRacksPrice: 0,
			parkingTypes: [],
			lockersTypes: [],
        };
    },
    methods: {
        clearFilter() {
            let lotFilter = {
                price: [0, 0],
                frontage: [0],
				unitGroup: "any",
				elevation: "any",
                modelType: ["any"],
                tags: ["any"],
                street: "any",
                premiums: ["any"],
                status: ["any"],
            };

            let elevationFilter = {
                price: [0, 0],
                frontage: [0],
                modelType: ["any"],
                beds: ["any"],
                baths: ["any"],
                unitGroup: "any",
				elevation: "any",
				tags: ["any"]
            };

            let lowriseFilter = {
				assignee: ["any"],
                price: [0, 0],
				frontage: [0],
				unitGroup: "any",
				elevation: "any",
				modelType: ["any"],
				tags: ["any"],
				street: "any",
				premiums: ["any"],
            };

            let highriseFilter = {
                assignee: ["any"],
                type: ["any"],
                price: [0, 0],
                sqft: [0, 0],
                beds: ["any"],
                baths: "any",
                floor: "any",
                exposure: ["any"],
                exterior: ["any"],
                tags: ["any"],
            };

			let addOnsFilter = {
                assignee: ["any"],
				parkingType: ["any"],
				lockersType: ["any"],
				parkingPrice: [0, 0],
				lockersPrice: [0, 0],
				bikeRacksPrice: [0, 0],
				tags: ["any"],
            };

            if (
                (['inventory', 'allocation'].includes(this.crumbs)) &&
                this.instance.productType == "lowrise"
            ) {
                this.$store.commit("UPDATE_LOWRISE_FILTER", lowriseFilter);
                this.filter = lowriseFilter;
            }

            if (
                this.crumbs == "priceManagement" &&
                this.instance.productType == "lowrise" &&
                this.priceManagement == "lot"
            ) {
                this.$store.commit("UPDATE_LOT_FILTER", lotFilter);
                this.filter = lotFilter;
            }

            if (
                this.crumbs == "priceManagement" &&
                this.instance.productType == "lowrise" &&
                this.priceManagement == "elevation"
            ) {
                this.$store.commit("UPDATE_ELEVATION_FILTER", elevationFilter);
                this.filter = elevationFilter;
            }

            if (this.instance.productType == "highrise") {
				if (this.showAddOnsFilter) {
					this.$store.commit("UPDATE_ADDONS_FILTER", addOnsFilter);
					this.filter = addOnsFilter;
				} else {
					this.$store.commit("UPDATE_HIGHRISE_FILTER", highriseFilter);
					this.filter = highriseFilter;
				}
			}
            this.filterApplied = false;
        },
		changeAddOnType(type, addOn){
			if (type != "any") {
                if (
                    this.filter[addOn].length == 1 &&
                    this.filter[addOn][0] == "any"
                ) {
                    this.filter[addOn].splice(0, 1);
                }
                if (!this.filter[addOn].includes(type)) {
                    this.filter[addOn].push(type);
                } else {
                    let index = this.filter[addOn].findIndex((x) => x == type);
                    this.filter[addOn].splice(index, 1);
                }
            } else {
                this.filter[addOn] = ["any"];
            }
            if (this.filter[addOn].length == 0) {
                this.filter[addOn] = ["any"];
            }
		},
		changeAddOnPriceRange(e, addOn) {
            this.filter[addOn] = e;
        },
        changePriceRange(e) {
            this.filter.price = e;
        },
        changeSqftRange(e) {
            this.filter.sqft = e;
        },
        changeTag(tag) {
            if (tag != "any") {
                if (
                    this.filter.tags.length == 1 &&
                    this.filter.tags[0] == "any"
                ) {
                    this.filter.tags.splice(0, 1);
                }
                if (!this.filter.tags.includes(tag)) {
                    this.filter.tags.push(tag);
                } else {
                    let index = this.filter.tags.findIndex((x) => x == tag);
                    this.filter.tags.splice(index, 1);
                }
            } else {
                this.filter.tags = ["any"];
            }
            if (this.filter.tags.length == 0) {
                this.filter.tags = ["any"];
            }
        },
        changeBed(bed) {
            if (bed != "any") {
                if (
                    this.filter.beds.length == 1 &&
                    this.filter.beds[0] == "any"
                ) {
                    this.filter.beds.splice(0, 1);
                }
                if (!this.filter.beds.includes(bed)) {
                    this.filter.beds.push(bed);
                } else {
                    let index = this.filter.beds.findIndex((x) => x == bed);
                    this.filter.beds.splice(index, 1);
                }
            } else {
                this.filter.beds = ["any"];
            }
            if (this.filter.beds.length == 0) {
                this.filter.beds = ["any"];
            }
        },
        changeBath(bath) {
            if (bath != "any") {
                if (
                    this.filter.baths.length == 1 &&
                    this.filter.baths[0] == "any"
                ) {
                    this.filter.baths.splice(0, 1);
                }
                if (!this.filter.baths.includes(bath)) {
                    this.filter.baths.push(bath);
                } else {
                    let index = this.filter.baths.findIndex((x) => x == bath);
                    this.filter.baths.splice(index, 1);
                }
            } else {
                this.filter.baths = ["any"];
            }
            if (this.filter.baths.length == 0) {
                this.filter.baths = ["any"];
            }
        },
        changeHighRiseType(type) {
            if (type != "any") {
                if (
                    this.filter.type.length == 1 &&
                    this.filter.type[0] == "any"
                ) {
                    this.filter.type.splice(0, 1);
                }
                if (!this.filter.type.includes(type)) {
                    this.filter.type.push(type);
                } else {
                    let index = this.filter.type.findIndex((x) => x == type);
                    this.filter.type.splice(index, 1);
                }
            } else {
                this.filter.type = ["any"];
            }
            if (this.filter.type.length == 0) {
                this.filter.type = ["any"];
            }
        },
        changeSize(size) {
            if (size != 0) {
                if (
                    this.filter.frontage.length == 1 &&
                    this.filter.frontage[0] == 0
                ) {
                    this.filter.frontage.splice(0, 1);
                }
                if (!this.filter.frontage.includes(size)) {
                    this.filter.frontage.push(size);
                } else {
                    let index = this.filter.frontage.findIndex(
                        (x) => x == size
                    );
                    this.filter.frontage.splice(index, 1);
                }
            } else {
                this.filter.frontage = [0];
            }
            if (this.filter.frontage.length == 0) {
                this.filter.frontage = [0];
            }
        },
        changePremium(premium) {
            if (premium != "any" && premium != "none") {
                if (
                    this.filter.premiums.length == 1 &&
                    (this.filter.premiums[0] == "any" || this.filter.premiums[0] == "none")
                ) {
                    this.filter.premiums.splice(0, 1);
                }
                if (!this.filter.premiums.includes(premium)) {
                    this.filter.premiums.push(premium);
                } else {
                    let index = this.filter.premiums.findIndex(
                        (x) => x == premium
                    );
                    this.filter.premiums.splice(index, 1);
                }
            } else if (premium == "none") {
                this.filter.premiums = ["none"];
            } else {
				this.filter.premiums = ["any"];
			}
            if (this.filter.premiums.length == 0) {
                this.filter.premiums = ["any"];
            }
        },
        changeType(type) {
            if (type != "any") {
                if (
                    this.filter.modelType.length == 1 &&
                    this.filter.modelType[0] == "any"
                ) {
                    this.filter.modelType.splice(0, 1);
                }
                if (!this.filter.modelType.includes(type)) {
                    this.filter.modelType.push(type);
                } else {
                    let index = this.filter.modelType.findIndex(
                        (x) => x == type
                    );
                    this.filter.modelType.splice(index, 1);
                }
            } else {
                this.filter.modelType = ["any"];
            }
            if (this.filter.modelType.length == 0) {
                this.filter.modelType = ["any"];
            }
        },
        changeExterior(ext) {
            if (ext != "any") {
                if (
                    this.filter.exterior.length == 1 &&
                    this.filter.exterior[0] == "any"
                ) {
                    this.filter.exterior.splice(0, 1);
                }
                if (!this.filter.exterior.includes(ext)) {
                    this.filter.exterior.push(ext);
                } else {
                    let index = this.filter.exterior.findIndex((x) => x == ext);
                    this.filter.exterior.splice(index, 1);
                }
            } else {
                this.filter.exterior = ["any"];
            }
            if (this.filter.exterior.length == 0) {
                this.filter.exterior = ["any"];
            }
        },
        changeExposure(exp) {
            if (exp != "any") {
                if (
                    this.filter.exposure.length == 1 &&
                    this.filter.exposure[0] == "any"
                ) {
                    this.filter.exposure.splice(0, 1);
                }
                if (!this.filter.exposure.includes(exp)) {
                    this.filter.exposure.push(exp);
                } else {
                    let index = this.filter.exposure.findIndex((x) => x == exp);
                    this.filter.exposure.splice(index, 1);
                }
            } else {
                this.filter.exposure = ["any"];
            }
            if (this.filter.exposure.length == 0) {
                this.filter.exposure = ["any"];
            }
        },
		changeAssignee(assignee) {
			assignee = assignee[assignee.length - 1];
			if (assignee && assignee != "any") {
				let index = this.filter.assignee.findIndex((x) => x == "any");
				if (index > -1) {
					this.filter.assignee.splice(index, 1);
				}
			} else {
				this.filter.assignee = ["any"];
			}
			if (this.filter.assignee.length == 0) {
				this.filter.assignee = ["any"];
			}
		},
        changeStatus(status) {
            if (status != "any") {
                if (
                    this.filter.status.length == 1 &&
                    this.filter.status[0] == "any"
                ) {
                    this.filter.status.splice(0, 1);
                }
                if (!this.filter.status.includes(status)) {
                    this.filter.status.push(status);
                } else {
                    let index = this.filter.status.findIndex(
                        (x) => x == status
                    );
                    this.filter.status.splice(index, 1);
                }
            } else {
                this.filter.status = ["any"];
            }
            if (this.filter.status.length == 0) {
                this.filter.status = ["any"];
            }
        },
        onClose() {
            this.$store.commit("CLOSE_FILTER");
        },
        submit() {
            //lowrise filter
            if (
                this.instance.productType == "lowrise" &&
                (['inventory', 'allocation'].includes(this.crumbs))
            ) {
                this.$store.commit("UPDATE_LOWRISE_FILTER", this.filter);
                // lot filter
            } else if (
                this.instance.productType == "lowrise" &&
                this.crumbs == "priceManagement" &&
                this.priceManagement == "lot"
            ) {
                this.$store.commit("UPDATE_LOT_FILTER", this.filter);
            } else if (
                this.instance.productType == "lowrise" &&
                this.crumbs == "priceManagement" &&
                this.priceManagement == "elevation"
            ) {
                this.$store.commit("UPDATE_ELEVATION_FILTER", this.filter);
            } else if (this.instance.productType == "highrise") {
				if (this.showAddOnsFilter) {
					this.$store.commit("UPDATE_ADDONS_FILTER", this.filter);
				} else {
					this.$store.commit("UPDATE_HIGHRISE_FILTER", this.filter);
				}
            }
            this.onClose();
        },
        getFilter(type) {
            if (type == "lowrise") {
                this.filter = JSON.parse(
                    JSON.stringify(this.$store.state.inventory.lowriseFilter)
                );
                this.price = [];

                if (
					this.filter.assignee[0] != "any" ||
                    this.filter.price[1] != 0 ||
                    this.filter.frontage[0] != 0 ||
                    this.filter.modelType[0] != "any" ||
                    this.filter.tags[0] != "any" ||
                    this.filter.street != "any" ||
                    this.filter.premiums[0] != "any" ||
                    this.filter.elevation != "any" ||
                    this.filter.unitGroup != "any"
                )
                    this.filterApplied = true;
            } else if (type == "lot") {
                this.filter = JSON.parse(
                    JSON.stringify(this.$store.state.inventory.lotFilter)
                );

                if (
                    this.filter.price[1] != 0 ||
                    this.filter.frontage[0] != 0 ||
                    this.filter.modelType[0] != "any" ||
                    this.filter.tags[0] != "any" ||
                    this.filter.street != "any" ||
                    this.filter.premiums[0] != "any" ||
                    this.filter.status[0] != "any"
                )
                    this.filterApplied = true;
            } else if (type == "elevation") {
                let elevOptions = this.$store.getters.elevOptions;
                let {
                    minPrice,
                    maxPrice,
                    prices,
                    minBeds,
                    maxBeds,
                    minBaths,
                    maxBaths,
                    beds,
                    baths,
                } = elevOptions;

                this.filter = JSON.parse(
                    JSON.stringify(this.$store.state.inventory.elevationFilter)
                );
                if (
                    this.filter.price[1] != 0 ||
                    this.filter.frontage[0] != 0 ||
                    this.filter.modelType[0] != "any" ||
                    this.filter.beds[0] != "any" ||
                    this.filter.baths[0] != "any" ||
                    this.filter.unitGroup != "any"
                )
                    this.filterApplied = true;

                this.bedOptions = beds.sort((a, b) => a - b);
                this.bathOptions = baths.sort((a, b) => a - b);
            } else if (type == "highrise" && !this.showAddOnsFilter) {
                let highRiseOptions = this.$store.getters.highRiseOptions;

                let {
                    minPrice,
                    maxPrice,
                    bedOptions,
                    baths,
                    prices,
                    minSqft,
                    maxSqft,
                    sqft,
                    exteriors,
                } = highRiseOptions;

                this.bedOptions = bedOptions.sort();
                this.bathOptions = baths.sort();
                this.minPrice = minPrice;
                this.maxPrice = maxPrice;
                this.price = prices;
                this.sqft = sqft;
                this.minSqft = minSqft;
                this.maxSqft = maxSqft;

                this.filter = JSON.parse(
                    JSON.stringify(this.$store.state.inventory.highriseFilter)
                );

                if (
                    this.filter.price[1] != 0 ||
                    this.filter.sqft[1] != 0 ||
                    this.filter.type[0] != "any" ||
                    this.filter.beds[0] != "any" ||
                    this.filter.baths != "any" ||
                    this.filter.floor != "any" ||
                    this.filter.exposure[0] != "any" ||
                    this.filter.assignee[0] != "any" ||
                    this.filter.exterior[0] != "any" ||
                    this.filter.tags[0] != "any"
                )
                    this.filterApplied = true;

                const defaultFilter = JSON.parse(
                    JSON.stringify(
                        this.$store.state.inventory.filterDrawer.filter
                    )
                );

                if (defaultFilter) {
                    this.filter = defaultFilter;
                    this.$store.commit("RESET_DEFAULT_FILTER");
                }

                if (prices && prices.length) {
                    if (!this.filter.price.length) {
                        this.filter.price = [minPrice, maxPrice];
                    }
                }
                if (sqft && sqft.length) {
                    if (!this.filter.sqft.length) {
                        this.filter.sqft = [minSqft, maxSqft];
                    }
                }
                if (exteriors && exteriors.length) {
                    this.highRiseExteriors = exteriors;
                }
            } else if (type == "highrise" && this.showAddOnsFilter) {
				let addOnsOptions = this.$store.getters.addOnsOptions

				let {
					parkingPrices,
					parkingTypes,
					minParkingPrice,
					maxParkingPrice,
					lockersPrices,
					lockersTypes,
					minLockersPrice,
					maxLockersPrice,
					bikeRacksPrices,
					minBikeRacksPrice,
					maxBikeRacksPrice
				} = addOnsOptions

				this.parkingTypes = parkingTypes;
				this.minParkingPrice = minParkingPrice;
                this.maxParkingPrice = maxParkingPrice;
				this.lockersTypes = lockersTypes;
				this.minLockersPrice = minLockersPrice;
				this.maxLockersPrice = maxLockersPrice;
				this.minBikeRacksPrice = minBikeRacksPrice;
				this.maxBikeRacksPrice = maxBikeRacksPrice;

				this.filter = JSON.parse(
					JSON.stringify(this.$store.state.inventory.addOnsFilter)
				);

				if (
					this.filter.parkingType[0] != "any" ||
					this.filter.lockersType[0] != "any" ||
					this.filter.parkingPrice[1] != 0 ||
					this.filter.lockersPrice[1] != 0 ||
					this.filter.bikeRacksPrice[1] != 0 ||
					this.filter.assignee[0] != "any" ||
					this.filter.tags[0] != "any"
				) {
					this.filterApplied = true;
				}

				const defaultFilter = JSON.parse(
					JSON.stringify(
						this.$store.state.inventory.filterDrawer.filter
					)
				);

				if (defaultFilter) {
					this.filter = defaultFilter;
					this.$store.commit("RESET_DEFAULT_FILTER");
				}

				if (parkingPrices && parkingPrices.length) {
					if (!this.filter.parkingPrice.length) {
						this.filter.parkingPrice = [minParkingPrice, maxParkingPrice];
					}
				}
				if (lockersPrices && lockersPrices.length) {
					if (!this.filter.parkingPrice.length) {
						this.filter.lockersPrice = [minParkingPrice, maxParkingPrice];
					}
				}
				if (bikeRacksPrices && bikeRacksPrices.length) {
					if (!this.filter.bikeRacksPrice.length) {
						this.filter.bikeRacksPrice = [minParkingPrice, maxParkingPrice];
					}
				}
			}
        },
    },
};
</script>

<style scoped lang="scss">
.clear-button:hover {
    cursor: pointer;
    text-decoration: underline;
}
.filter-options:hover {
    background-color: #ece9f1;
}
.grid-display-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
}
</style>
<style>
@media screen and (max-width: 600px) {
    .adjust-drawer-width .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}
</style>
